import React from 'react';
import styles from './Css/Navbar.module.css';

function Navbar() {
  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.container}>
          <a className={styles.email} href="mailto:sales@pantagonpackaging.com">
          sales@pantagonpackaging.com
          </a>
          <div className={styles.rightSide}>
            <span className={styles.phone} style={{cursor:"pointer"}} onClick={() => window.open("tel:+19046684688")}>Call Us</span>
            <a className={styles.quote} href="/getquote">
              Get a Quote
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
