import React from "react";
import logo from "../assets/images/PentagonePng.png";
import style from "./Css/Footer.module.css";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaEnvelope,FaTwitter, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <div>
            <footer className={style.footer}>
                <div className={style.topSection}>
                    <div className={style.leftSide}>
                        <p>Get connected with us on social networks:</p>
                    </div>
                    <div className={style.rightSide}>
                        <div className={style.socialIcons}>
                            <FaFacebookF className={style.icon} onClick={() => window.open("https://www.facebook.com/pantagonpackaging")} />
                            <FaInstagram className={style.icon} onClick={() => window.open("https://www.instagram.com/pantagonpackaging/")} />
                            <FaTwitter className={style.icon} onClick={() => window.open("https://x.com/PantagonPackag")} />
                            
                            <FaLinkedinIn className={style.icon} onClick={() => window.open("https://www.linkedin.com/company/pentagon-packaging/")} />
                        </div>
                    </div>
                </div>
                <div className={style.columns}>
                    <div className={style.logoColumn}>
                        <img src={logo} alt="Logo" className={style.logo} />
                    </div>
                    <div className={style.column}>
                        <h3>Product</h3>
                        <ul>
                            <li style={{cursor:"pointer"}} onClick={()=> navigate("/allindustries")}>Industries</li>
                            <li style={{cursor:"pointer"}} onClick={() => navigate("/allshapestyle")}>Shape & Style</li>
                            <li style={{cursor:"pointer"}} onClick={() => navigate("/allmaterial")}>Material</li>
                        </ul>
                    </div>
                    <div className={style.column}>
                        <h3>Useful Links</h3>
                        <ul>
                            <li style={{cursor:"pointer"}} onClick={() => navigate("/")}>Home</li>
                            <li>Get a Quote</li>
                            <li style={{cursor:"pointer"}} onClick={() => navigate("/allproducts")}>Products</li>
                        </ul>
                    </div>
                    <div className={style.column}>
                        <h3>Contact</h3>
                        <ul>
                            <li><FaEnvelope className={style.icon} /> <span style={{cursor:"pointer"}} onClick={() => window.open("mailto:sales@pantagonpackaging.com")} > sales@pantagonpackaging.com </span></li>
                            <li><FaPhoneAlt className={style.icon} /> <span style={{cursor:"pointer"}} onClick={() => window.open("tel:+17702947065")}> +1 (770) 2947065 </span> </li>
                            
                            <li><FaMapMarkerAlt className={style.icon} />  <b>LLC:</b> 2150 spencer RD Apt 3D Orange park florida, 32073</li>
                            <li><FaMapMarkerAlt className={style.icon} />  <b>Head Office:</b> 1399 Herrington RD Apt 7208 Lawrenceville 30044 Georgia</li>
                        </ul>
                    </div>
                </div>
                <div className={style.copyrightRow}>
                    <p>© 2023 Copyright: pentagonpackaging.com</p>
                </div>
            </footer>
        </div>
    ); 
};

export default Footer;
