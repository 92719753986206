import React, { useEffect, useState } from 'react';
// import axios from 'axios'; // Uncomment this when you integrate API
import styles from './Css/Specification.module.css';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Env from '../util/Env';
import { getAsset } from '../util/helper';

const Specification = () => {
  const [activeTab, setActiveTab] = useState('specification');
  const [data, setData] = useState({});

useEffect(() => {
  // Fetch data from API when the component mounts
  axios.get(`${Env.server}/api/specification/GetAllSpecificationFieldsData`)
  .then((response) => {
    console.log("abc11------", response.data.data)
    setData(response.data.data);

  })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });


}, []);


  const renderContent = () => {
    switch (activeTab) {
      case 'specification':
        return (
          <>
   <Helmet>
                <title>Custom Packaging Specifications | Pentagon Packaging</title>
                <meta name="description" content="Learn about the specific requirements for custom packaging solutions at Pentagon Packaging. We provide detailed specifications to ensure your packaging meets both functionality and aesthetic standards." />
                <meta property="og:title" content="Custom Packaging Specifications | Pentagon Packaging" />
            </Helmet>

<section>
  <h2>Specifications</h2>
  {data.specification && data.specification.data && data.specification.data.length > 0 ? (
    <>
      <p>{data.specification.description || "No description available at this time."}</p>
      <table className={styles.specTable}>
        <thead className={styles.headertablespecification}>
          <tr>
            <th>Dimension</th>
            <th>Detail</th>
          </tr>
        </thead>
        <tbody>
          {data.specification.data.map((item, index) => (
            <tr key={index}>
              <td>{item.dimension || "N/A"}</td>
              <td>{item.detail || "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  ) : (
    <p className={styles.noDataMessage}>No specifications or description available at this time.</p>
  )}
</section>
          </>

        );
      case 'material':
        return (
          <>
               <Helmet>
                <title>Custom Packaging Materials | Pentagon Packaging</title>
                <meta name="description" content="Discover a wide range of premium materials for your custom packaging solutions at Pentagon Packaging. Choose from eco-friendly, durable, and innovative materials for your packaging needs.
" />
                <meta property="og:title" content="Custom Packaging Materials | Pentagon Packaging" />
            </Helmet>
          <section>
          {data.material && data.material && data.material.length > 0 ? (
            <>
              <h2>{data.material.heading || "Explore Our Materials"}</h2>
              <p>{data.material.description || "Discover the high-quality materials we offer below."}</p>
              <div className={styles.gridContainer}>
                {data.material.slice(0, 6).map((item, index) => (
                  <div key={index} className={styles.gridItem}>
                    <img
                      src={getAsset(item.image && item.image[0] ? item.image[0] : "default-image-path.jpg")}
                      alt={item.title || "Material Image"}
                      className={styles.itemImage}
                    />
                    <p><b>{item.title || "Material Title"}</b></p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p className={styles.noDataMessage}>We currently don't have any materials to display. Please check back soon for updates!</p>
          )}
        </section>
        </>
        
        );
      case 'printingMethod':
        return (
          <>
            <Helmet>
                <title>Custom Packaging Printing Methods | Pentagon Packaging</title>
                <meta name="description" content="Explore the best printing methods for your custom packaging at Pentagon Packaging. From offset to digital printing, we offer solutions that bring your designs to life with precision and quality." />
                <meta property="og:title" content="Custom Packaging Printing Methods | Pentagon Packaging" />
            </Helmet>

          <section>
  {data.printingMethod && data.printingMethod.data && data.printingMethod.data.length > 0 ? (
    <>
      <h2>{data.printingMethod.heading || "Explore Our Printing Methods"}</h2>
      <p>{data.printingMethod.description || "Learn about the different printing methods we offer below."}</p>
      <div className={styles.gridContainer}>
        {data?.printingMethod.data.slice(0, 6).map((item, index) => (
          <div key={index} className={styles.gridItem}>
            <img
              src={getAsset(item.image && item?.image)}
              alt={item.title || "Printing Method Image"}
              className={styles.itemImage}
            />
            <p><b>{item.title || "Printing Method Title"}</b></p>
          </div>
        ))}
      </div>
    </>
  ) : (
    <p className={styles.noDataMessage}>
      We're currently updating our printing methods information. Please check back soon for the latest updates!
    </p>
  )}
</section>
</>
        );
      case 'inkType':
        return (
          <>
             <Helmet>
                <title>Custom Ink Types for Packaging | Pentagon Packaging</title>
                <meta name="description" content="Choose the right ink type for your custom packaging needs at Pentagon Packaging. We offer eco-friendly, vibrant, and durable inks that ensure your designs stand out and last." />
                <meta property="og:title" content="Custom Ink Types for Packaging | Pentagon Packaging" />
            </Helmet>
          <section>
  {data.inkType && data.inkType.data && data.inkType.data.length > 0 ? (
    <>
      <h2>{data.inkType.heading || "Explore Our Ink Types"}</h2>
      <p>{data.inkType.description || "Discover the various ink types we use for quality printing."}</p>
      <div className={styles.gridContainer}>
        {data.inkType.data.slice(0, 6).map((item, index) => (
          <div key={index} className={styles.gridItem}>
            <img
              src={getAsset(item.image && item.image[0] ? item.image[0] : "default-image-path.jpg")}
              alt={item.title || "Ink Type Image"}
              className={styles.itemImage}
            />
            <p><b>{item.title || "Ink Type Title"}</b></p>
          </div>
        ))}
      </div>
    </>
  ) : (
    <p className={styles.noDataMessage}>
      We're updating our ink type details. Please check back soon to explore our options!
    </p>
  )}
</section>
          </>

        );
      case 'finishing':
        return (
          <>
             <Helmet>
                <title>Custom Packaging Finishing Options | Pentagon Packaging</title>
                <meta name="description" content="Discover a range of finishing options for your custom packaging at Pentagon Packaging. From matte to gloss finishes, we add the perfect final touch to enhance your product's presentation." />
                <meta property="og:title" content="Custom Packaging Finishing Options | Pentagon Packaging" />
            </Helmet>
          <section>
          {data.finishing && data.finishing.data && data.finishing.data.length > 0 ? (
            <>
              <h2>{data.finishing.heading || "Explore Our Finishing Options"}</h2>
              <p>{data.finishing.description || "Check out the high-quality finishing options we offer below."}</p>
              <div className={styles.gridContainer}>
                {data.finishing.data.slice(0, 6).map((item, index) => (
                  <div key={index} className={styles.gridItem}>
                    <img
                      src={getAsset(item.image && item.image[0] ? item.image[0] : "default-image-path.jpg")}
                      alt={item.title || "Finishing Option Image"}
                      className={styles.itemImage}
                    />
                    <p><b>{item.title || "Finishing Option Title"}</b></p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p className={styles.noDataMessage}>
              We are currently updating our finishing options. Please check back soon to discover our premium selections!
            </p>
          )}
        </section>
        </>
        );
        case 'addOns':
        return (
          <>
             <Helmet>
                <title>Custom Packaging Add-Ons | Pentagon Packaging</title>
                <meta name="description" content="Customize your packaging further with unique add-ons from Pentagon Packaging. From custom handles to inserts, we provide extra features that elevate the functionality and appeal of your packaging." />
                <meta property="og:title" content="Custom Packaging Add-Ons | Pentagon Packaging" />
            </Helmet>
          <section>
          {data.addOns && data.addOns.data && data.addOns.data.length > 0 ? (
            <>
              <h2>{data.addOns.heading || "Explore Our Add-Ons"}</h2>
              <p>{data.addOns.description || "Check out the various add-ons we offer to enhance your products."}</p>
              <div className={styles.gridContainer}>
                {data.addOns.data.slice(0, 6).map((item, index) => (
                  <div key={index} className={styles.gridItem}>
                    <img
                      src={getAsset(item.image && item.image[0] ? item.image[0] : "default-image-path.jpg")}
                      alt={item.title || "Add-On Image"}
                      className={styles.itemImage}
                    />
                    <p><b>{item.title || "Add-On Title"}</b></p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p className={styles.noDataMessage}>
              We're currently updating our add-ons. Please check back soon to see what we have in store for you!
            </p>
          )}
        </section>
        </>
        );

        
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
    <div className={styles.tabBar}>
      {['specification', 'material', 'printingMethod', 'inkType', 'finishing', 'addOns'].map(tab => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`${activeTab === tab ? styles.activeTab : ''}`}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </button>
      ))}
    </div>
    <div className={styles.contentContainer}>
      {renderContent()}
    </div>
  </div>  );
};

export default Specification;